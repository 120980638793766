import React, { useState } from "react";
import Banner from "../elements/Banner";
import BannerBottom from "../elements/BannerBottom";
import bannerImg from "../../assets/images/career/banner-careers.jpg";
import { Helmet } from "react-helmet";
import PdfModal from "../elements/PdfModal";
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Swal from 'sweetalert2'
//6LfHHgQqAAAAALqbuu3e8Pv-SbooXMXx6dqDNL07

const Careers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [capVal, setCapVal] = useState(null);

  const [model, setModel] = useState(false);

  const handleOpenModal = (pdfUrl) => {
    setCurrentPdfUrl(pdfUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentPdfUrl("");
  };

  const [formData, setFormData] = useState({
      name: '',
      phone: '',
      email: ''
  });

  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    phone: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]{11}$/, 'Phone number must be 11 digits'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    position: Yup.string().required('Please select a position'),
    resume: Yup.mixed()
      .required('Resume is required')
      .test('fileSize', 'File too large', (value) => !value || value.size <= 1024 * 1024)
      .test(
        'fileType',
        'Unsupported file format',
        (value) => !value || ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/png'].includes(value.type)
      ),
    message: Yup.string().required('Message is required'),
  });
  

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('phone', values.phone);
    formData.append('email', values.email);
    formData.append('position', values.position);
    formData.append('message', values.message);
    formData.append('resume', values.resume);

    fetch('https://efc.com.ph/phpmail/application-mail.php', {
      method: 'POST',
      body: formData
    })
      .then((response) => response.text())
      .then((result) => {
        setLoading(false);
        setModel(false);
          Swal.fire({
            title: "Good job!",
            text: 'Application has been submitted successfully.',
            icon: "success"
          });
       
        console.log(result);
        setSubmitting(false);
      })
      .catch((error) => {
        alert('Error: ' + error.message);
        setSubmitting(false);
      });
  };


  return (
    <>
      {model && (
        <div className="apply-model">
          <div className="apply-model-content">
            <div className="apply-model-header mb-4">
              <h2>Apply Now</h2>
              <button className="close-btn" onClick={() => setModel(false)}>
                Close
              </button>
            </div>
            <div className="apply-model-body">
            <Formik
      initialValues={{
        name: '',
        phone: '',
        email: '',
        position: '',
        resume: null,
        message: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched, 
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="field-container d-mb-flex">
            <div>
              <input
                type="text"
                id="name"
                name="name"
                className="custom-field"
                placeholder="Your Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {touched.name && errors.name ? <div className="error">{errors.name}</div> : null}
            </div>
            <div>
              <input
                type="text"
                id="phone"
                name="phone"
                placeholder="Your Phone Number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
              />
              {touched.phone && errors.phone ? <div className="error">{errors.phone}</div> : null}
            </div>
          </div>

          <div className="field-container d-mb-flex">
            <div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
              {touched.email && errors.email ? <div className="error">{errors.email}</div> : null}
            </div>
            <div>
              <select
                name="position"
                id="position"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.position}
              >
                <option value="">Select Position</option>
                <option value="Key Accounts Management">Key Accounts Management</option>
                <option value="Distribution Management">Distribution Management</option>
                <option value="Trade Marketing">Trade Marketing</option>
                <option value="Sales Information & Sales Capability">Sales Information & Sales Capability</option>
                <option value="Supply Chain and Logistics">Supply Chain and Logistics</option>
                <option value="Human Resources & Corporate Services">Human Resources & Corporate Services</option>
                <option value="Finance and Accounting">Finance and Accounting</option>
                <option value="Management Trainee">Management Trainee</option>
              </select>
              {touched.position && errors.position ? <div className="error">{errors.position}</div> : null}
            </div>
          </div>

          <div className="field-container">
            <input
              type="file"
              id="resume"
              name="resume"
              className="resume"
              placeholder="Upload your CV"
              onChange={(event) => setFieldValue('resume', event.currentTarget.files[0])}
            />
            {touched.resume && errors.resume ? <div className="error">{errors.resume}</div> : null}
          </div>

          <div className="field-container">
            <textarea
              id="message"
              name="message"
              placeholder="Message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            ></textarea>
            {touched.message && errors.message ? <div className="error">{errors.message}</div> : null}
          </div>

          <ReCAPTCHA sitekey="6LfHHgQqAAAAALqbuu3e8Pv-SbooXMXx6dqDNL07" onChange={(val) => setCapVal(val)} />

          <div className="field-container">
            <button type="submit" className="apply-now" disabled={!capVal || isSubmitting}>
             {isLoading ? 'Please wait..' : 'Submit' } 
            </button>
          </div>
        </form>
      )}
    </Formik>
            </div>
          </div>
        </div>
      )}

      <Helmet>
        <title data-react-helmet="true">
          Career with us | Ecossential Foods Corporation
        </title>
        <link rel="canonical" href="https://efc.com.ph" />
        <link rel="profile" href="http://gmpg.org/xfn/11" />
        <link rel="pingback" href="https://efc.com.ph/xmlrpc.php" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        ></meta>
        <link rel="profile" href="http://gmpg.org/xfn/11" />
        <link rel="pingback" href="https://efc.com.ph/xmlrpc.php" />
        <meta
          data-react-helmet="true"
          name="title"
          content="Group of Companies"
        />
        <meta
          name="keywords"
          content="Career with EFC, Ecossential Foods Corporation, Top distribution company"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Join Ecossential Foods Corporation, the leading FMCG distribution company in Philippines."
        />
        <link rel="cannonical" href="/careers" />
      </Helmet>
      <Banner image={bannerImg} title="/CAREERS" />
      <BannerBottom
        heading={`WE ARE A COMPANY WITH PURPOSE.`}
        headingTwo="LET US HELP YOU FIND YOURS."
      />

      <section className="career mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <p className="colorGrey justify font18">
                We focus on two key concerns: the efficient distribution of our
                clients’ products thus strengthening their brands in our
                markets, and building a team of experts of diverse talents and
                backgrounds allowing each one an equal opportunity for growth.{" "}
              </p>
              <p className="colorGrey justify font18">
                More importantly, we seek to go beyond improving our employees’
                skills and building their networks. We are here to help them
                discover their purpose and allow them to feed the dreams they
                have for themselves, their families, and their tomorrows.{" "}
              </p>
              <p className="justify bold4 font18">
                If you have experience in any of the skills listed below and are
                interested to grow with us, please send your CV to
                <a href="mailto:efccareers@efc.com.ph">
                  {" "}
                  efccareers@efc.com.ph
                </a>
              </p>
              <ul className="careerUl p-3">
                {[
                  {
                    title: "Key Accounts Management",
                    url: "./pdf/Key Accounts Management.pdf",
                  },
                  {
                    title: "Distribution Management",
                    url: "./pdf/Distribution Management.pdf",
                  },
                  {
                    title: "Trade Marketing",
                    url: "./pdf/Trade Marketing.pdf",
                  },
                  {
                    title: "Sales Information & Sales Capability",
                    url: "./pdf/Sales Information & Sales Capability.pdf",
                  },
                  {
                    title: "Supply Chain and Logistics",
                    url: "./pdf/Supply Chain and Logistics.pdf",
                  },
                  {
                    title: "Human Resources & Corporate Services",
                    url: "./pdf/Human Resources & Corporate Services.pdf",
                  },
                  {
                    title: "Finance and Accounting",
                    url: "./pdf/Finance and Accounting.pdf",
                  },
                  {
                    title: "Management Trainee",
                    url: "./pdf/Management Trainee.pdf",
                  },
                ].map((item, index) => (
                  <li key={index}>
                    <span>{item.title}</span>
                    <span className="buttonCareer">
                      <button onClick={() => handleOpenModal(item.url)}>
                        View More
                      </button>
                    </span>
                  </li>
                ))}
              </ul>
              <PdfModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                pdfUrl={currentPdfUrl}
              />

              <div className="text-center">
                <button className="apply-now" onClick={()=>setModel(true)} >Apply Now</button>
              </div>
            </div>
          </div>
        </div>
        <div className="sb"></div>
      </section>
    </>
  );
};

export default Careers;
