import React from 'react'
import { Helmet } from 'react-helmet'

const CookiePolicy = () => {
    return (
        <>
            <Helmet>

                <title data-react-helmet="true">
                    Cookie Policy - Ecossential Foods Corporation
                </title>
                <link rel="canonical" href="https://efc.com.ph/cookie-policy" />
                <link rel="profile" href="http://gmpg.org/xfn/11" />
                <link rel="pingback" href="https://efc.com.ph/xmlrpc.php" />
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
                <link rel="profile" href="http://gmpg.org/xfn/11" />
                <link rel="pingback" href="https://efc.com.ph/xmlrpc.php" />
                <meta data-react-helmet="true" name="title" content="Group of Companies" />
                <meta name="keywords" content="Ecossential Foods Corporation, distribution business, product distribution, distributors company, retail philippines, retail business in the philippines" />
                <meta name="robots" content="index, follow" />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                <meta name="language" content="English" />
                <meta
                    data-react-helmet="true"
                    name="description"
                    content="World-class distributor and marketer of diverse consumer goods"
                />

            </Helmet>


            <section className="privacy">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-md-12">
                            <h1 className="headingPrivacy mb-4">Cookie Policy</h1>
                            <p>This Cookie Policy explains how Ecossential Foods Corp ("we", "us", "our") uses cookies and similar technologies to recognize you when you visit our website <a href='https://efc.com.ph'>https://efc.com.ph ("Website")</a>. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>

                            <h3 className='text-uppercase'>What are cookies?</h3>

                           
                            <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

                            <h3 className='text-uppercase'>How do we use cookies?</h3>
                            <p><strong>We use cookies to:</strong></p>
                            <ul>
                                <li><strong>Make our website work:</strong> These cookies are necessary for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</li>

                                <li><strong>Enhance your experience:</strong> These cookies recognize you when you return to our website and personalize content for you and remember your preferences (for example, your choice of language or region).</li>
                                <li><strong>Performance and analytics: </strong> These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This helps us to improve the way our website works.</li>
                                <li><strong>Marketing: </strong> These cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user.</li>
                            </ul>
                            
                            <h3 className='text-uppercase'>What types of cookies do we use?</h3>
                            <ul>
                                <li><strong>Session cookies:</strong> These are temporary cookies that are erased once you close your browser.</li>
                                <li><strong>Persistent cookies:</strong> These cookies remain on your device for a longer period of time or until you delete them manually.</li>
                            </ul>
                            <h3 className='text-uppercase'>How to control cookies?</h3>
                            <p>You can control and manage cookies in various ways. Please note that removing or blocking cookies can affect your user experience and some parts of our website may no longer be fully accessible.</p>
                            <ul>
                                <li><strong>Browser controls:</strong> You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted.</li>
                                <li><strong>Third-party tools:</strong> Some third-party tools allow you to decline cookies from certain advertisers.</li>
                            </ul>

                            <h3 className='text-uppercase'>Third Party Cookies</h3>
                            <ul>
                                <li>This Third-Party Cookie Policy outlines how third-party cookies are used on our website through Google Analytics ("Google"). This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the web. It helps us understand how you use the site and ways that we can improve your experience. These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.</li>
                                <li>For more information on Google Analytics cookies, see the official Google Analytics page. (How Google uses cookies – Privacy & Terms – Google )</li>
                                <li>The Google AdSense service we use to serve advertising uses a DoubleClick cookie to serve more relevant ads across the web and limit the number of times that a given ad is shown to you. For more information on Google AdSense see the official Google AdSense privacy FAQ. (AdSense policy FAQs – Google AdSense Help )</li>
                            </ul>
                          
                            <h3 className='text-uppercase'>Changes to this Cookie Policy</h3>
                            <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CookiePolicy