import React from 'react'

const Cookie = () => {
  return (
    <div className='coming-soon'>


        <h1>Coming Soon...</h1>
    </div>
  )
}

export default Cookie
